<div class="option-card-body">
    <div class="option-card-heading content-header">
        <span class="fas fa-balance-scale"></span>
        {{ 'TERMS.DATA-PROCESS-PATIENTUS.HEADER' | translate }}
    </div>
    <ul class="term-points">
        <li>{{ 'TERMS.DATA-PROCESS-PATIENTUS.POINTS.1' | translate }}
            (<a routerLink="/{{dataSecurityRoute}}">{{ 'TERMS.DATA-PROCESS-PATIENTUS.POINTS.2' | translate }}</a>)
        </li>
    </ul>
    <div class="body-text-normal">
        <div class="body-text-normal">
            {{ 'TERMS.DATA-PROCESS-PATIENTUS.BODY.PART-1' | translate }}
        </div>
        <div class="body-text-normal">
            {{ 'TERMS.DATA-PROCESS-PATIENTUS.BODY.PART-2' | translate }}
            (<a href="{{ 'TERMS.DATA-PROCESS-PATIENTUS.BODY.PART-3' | translate }}">
                {{'TERMS.DATA-PROCESS-PATIENTUS.BODY.PART-3' | translate }}
            </a>)
            {{ 'TERMS.DATA-PROCESS-PATIENTUS.BODY.PART-4' | translate }}
            (<a href="{{ 'TERMS.DATA-PROCESS-PATIENTUS.BODY.PART-5' | translate }}">
                {{ 'TERMS.DATA-PROCESS-PATIENTUS.BODY.PART-5' | translate }}
            </a>).
        </div>
        <div class="body-text-normal">
            {{ 'TERMS.DATA-PROCESS-PATIENTUS.BODY.PART-6' | translate }}
        </div>
    </div>
</div>

