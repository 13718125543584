<div class="legal">
    <h5>AGB für Videosprechstunde</h5>
    <p>Allgemeine Geschäftsbedingungen der jameda GmbH, Balanstraße 71a, 81541 München („jameda”), für die Nutzung
        der Videosprechstunde unter:</p>
    <ul>
        <li>„security.patientus.de“ (Webseite), abrufbar insbesondere unter <a href="https://security.patientus.de">security.patientus.de</a>,</li>
        <li>allen weiteren Onlineangebot unter den Subdomains von [...].patientus.de (Bsp. (NamederWhitelabel).patientus.de)</li>
    </ul>
    <br />
    <strong>Vorbemerkung</strong>
    <p>Auf jameda haben Patienten die Möglichkeit an Videosprechstunden mit dem behandelnden Heilberufler (Ärzte,
        Heilpraktiker, Psychologen, Hebammen, Physiotherapeuten, Logopäden, Tierärzte), Arztpraxen, Kliniken und
        entsprechende Einrichtungen bzw. entsprechende Berufsträger und durch solche Berufsträger unterhaltene
        Einrichtungen) teilzunehmen, – sofern dieser Videosprechstunden anbietet. Hierfür gelten die vorliegenden
        Allgemeinen Geschäftsbedingungen (AGB) für Videosprechstunden in Verbindung mit den generellen
        Nutzungsrichtlinien unter <a href="https://www.jameda.de/jameda/agb.php?show=allgemein" target="_blank">
        https://www.jameda.de/jameda/agb.php</a>.
    </p>
    <p>Für die Bereitstellung von Diensten für Premium-Kunden (Heilberufler) gelten dagegen ausschließlich die
        Allgemeinen Geschäftsbedingungen (AGB) für Premium-Kunden bei jameda.de, die Sie unter
        <a href="www.jameda.de/allgemeine-geschaeftsbedingungen#terms-for-customers" target="_blank">
            www.jameda.de/allgemeine-geschaeftsbedingungen#terms-for-customers</a> abrufen können.</p>
    <ol>
        <li>Geltungsbereich / Vertragsgegenstand
            <ol>
                <li>Diese AGB gelten ausschließlich für die Nutzung der Videosprechstunde durch Patienten.</li>
                <li>Andere Angebote von jameda sind nicht Gegenstand dieser AGB.</li>
            </ol>
        </li>
        <li>Leistungsumfang
            <ol>
                <li>jameda bietet einen Online-, Audio- und Kommunikations-Service an, mit dem Patienten mit
                    Heilberuflern in Kontakt treten können. Die Patienten können mit den Heilberuflern eine
                    Videosprechstunde vereinbaren und durchfürhren. Die Videosprechstunde findet über eine
                    verschlüsselte Verbindung unmittelbar zwischen Heilberufler und Patient statt. jameda stellt dabei
                    ausschließlich die Nutzung des Service zur Verfügung.
                </li>
                <li>Für die Inhalte der Kommunikation zwischen Heilberufler und Patient ist jameda nicht verantwortlich.
                    jameda bietet über die auf den Internetseiten
                    <a href="www.jameda.de" target="_blank">www.jameda.de</a> und <a href="www.patientus.de" target="_blank">www.patientus.de</a>
                    hinausgehende Leistungsbeschreibung
                    keine medizinischen oder therapeutischen Leistungen an. Weder die Nutzung von jameda noch die
                    Vereinbarung von Videosprechstunden mit Heilberuflern ersetzen einen traditionellen Arztbesuch.
                    Suchen Sie einen Arzt oder Psychotherapeuten vor Ort oder in dringenden Fällen eine Notfallambulanz
                    auf. Für die medizinische und therapeutische Versorgung über das Internet gelten besondere
                    berufsrechtliche Bestimmungen und Einschränkungen. Das Leistungsangebot ist daher auf die aktuellen
                    rechtlichen Möglichkeiten begrenzt. Besondere Hinweise bei der Leistungsbeschreibung und
                    Leistungserbringung sind verbindlich.
                </li>
                <li>Die Nutzung von jameda ist für die Patienten kostenfrei. Für die von Heilberuflern erbrachten
                    Dienstleistungen können von diesen ggfs. gesonderte Honorare erhoben werden. Diese werden dem
                    Patienten deutlich kenntlich gemacht.
                </li>
                <li>Sobald der Patient mit einem Heilberufler Kontakt aufnimmt, kommt ein gesonderter Vertrag mit dem
                    Heilberufler zustande. Hierbei können dem Patienten Kosten entstehen. Hierüber wird der Patient
                    durch den Heilberufler gesondert informiert.
                </li>
            </ol>
        </li>
        <li>Nutzung
        </li>
        <ol>
            <li>Die Nutzung der jameda Videosprechstunde setzt voraus, dass der Patient sämtliche in der
                Terminbuchungs-Maske abgefragten Daten wahrheitsgemäß und vollständig an jameda übermittelt. Für die
                Nutzung muss der Patient mindestens 18 Jahre alt und unbeschränkt geschäftsfähig sein.
            </li>
            <li>Durch das Akzeptieren der Allgemeinen Geschäftsbedingungen im Zuge der Terminbuchung via Opt-In kommt
                eine Nutzungsvereinbarung nach Maßgabe dieser Allgemeinen Geschäftsbedingungen zustande.
            </li>
            <li>Eine Terminbuchung des Patienten ist nicht erforderlich, wenn die Anmeldung des Patienten über eine von
                jameda generierte und vom Heilberufler übermittelte TAN erfolgt. In einem solchen Fall wird der Patient
                bei Eingabe der TAN aufgefordert diesen Allgemeinen Geschäftsbedingungen zuzustimmen und die
                Datenschutzerklärung von jameda zur Kenntnis zu nehmen. Eine darüberhinausgehende Eingabe von Daten
                durch den Patienten ist nicht erforderlich. Sonach begibt sich der Patient in das virtuelle Wartezimmer
                und die Videosprechstunde beginnt, sobald der Heilberufler den Termin startet.
            </li>
            <li>Es besteht kein Rechtsanspruch auf die Inanspruchnahme der Leistungen von jameda. jameda ist berechtigt,
                die Registrierung ohne Angabe von Gründen zu verweigern.
            </li>
        </ol>
        <li>Nutzungerechte
            <ol>
                <li>Dem Patienten stehen ausschließlich die nach diesen allgemeinen Geschäftsbedingungen eingeräumten
                    Rechte an dem Internetangebot zu.
                </li>
                <li>Die über den Service von jameda veröffentlichten Inhalte, Informationen, Bilder, Videos, Datenbanken
                    sind grundsätzlich urheberrechtlich geschützt und in der Regel Eigentum oder lizensiert von jameda.
                </li>
                <li>Die Inhalte des Services dürfen nur für persönliche und nicht für kommerzielle Zwecke genutzt oder
                    vervielfältigt werden. Eine Weitergabe der Inhalte ist ohne ausdrückliche, schriftliche Zustimmung
                    von jameda untersagt.
                </li>
            </ol>
        </li>
        <li>Patientendaten / Datenschutz
            <ol>
                <li>Videosprechstunden werden durch jameda weder aufgenommen noch gespeichert.</li>
                <li>Im Übrigen wird auf die auf der Webseite von jameda abrufbare Datenschutzerklärung (<a
                    href="https://www.jameda.de/datenschutz" target="_blank">www.jameda.de/datenschutz</a>)
                    verwiesen.
                </li>
                <li>Alle Heilberufler bei jameda unterstehen der beruflichen Schweigepflicht gegenüber Dritten. In
                    Ausnahmefällen, in denen nach Beurteilung der Heilberufler eine Gefährdung für den Patienten oder
                    Drittpersonen angenommen werden muss (Suizid- oder Tötungsabsichten, Missbrauch von Kindern etc.)
                    besteht eine gesetzliche Auskunftspflicht.
                </li>
            </ol>
        </li>
        <li>Verfügbarkeit
            <ol>
                <li>Das Leistungsangebot steht in der Regel 24 Stunden am Tag zur Verfügung. Hiervon ausgenommen sind
                    die Zeiten, in denen Datensicherungsarbeiten vorgenommen und Systemwartungs- oder
                    Programmpflegearbeiten am System oder der Datenbank durchgeführt werden.
                </li>
                <li>jameda ist ständig bemüht, einen ordnungsgemäßen Betrieb des Angebots sicherzustellen, steht jedoch
                    nicht für die ununterbrochene Nutzbarkeit bzw. Erreichbarkeit des Angebots ein und haftet
                    insbesondere nicht für technisch bedingte Verzögerungen, Unterbrechungen oder Ausfälle.
                </li>
            </ol>
        </li>
        <li>Haftung
            <ol>
                <li>Die Haftung von jameda wird, gleich aus welchem Rechtsgrund, für durch sie, ihre gesetzlichen
                    Vertreter oder ihre jeweiligen Erfüllungsgehilfen verursachte Schäden – vorbehaltlich Absatz 2 – wie
                    folgt beschränkt:
                    <ul>
                        <li>Im Falle der leicht fahrlässigen Verletzung einer wesentlichen Pflicht aus dem
                            Schuldverhältnis („Kardinalpflicht“) haftet jameda der Höhe nach begrenzt auf den bei
                            Vertragsschluss vorhersehbaren, vertragstypischen Schaden. „Kardinalpflichten“ sind solche
                            Pflichten, deren Erfüllung die ordnungsgemäße Durchführung des Vertrages überhaupt erst
                            ermöglicht und auf deren Einhaltung eine Vertragspartei regelmäßig vertrauen darf;
                        </li>
                        <li>jameda haftet nicht für die leicht fahrlässige Verletzung nicht wesentlicher Pflichten aus
                            dem Schuldverhältnis.
                        </li>
                    </ul>
                </li>
                <li>Die vorgenannten Haftungsbeschränkungen gelten nicht bei grober Fahrlässigkeit oder vorsätzlichem
                    Handeln sowie in Fällen zwingender gesetzlicher Haftung, insbesondere bei Übernahme einer Garantie
                    oder bei schuldhaften Verletzungen des Lebens, des Körpers und der Gesundheit.
                </li>
            </ol>
        </li>
        <li>Pflichten des Nutzers
            <p>Der Patient ist verpflichtet Angaben, die für die Bereitstellung der Leistungen benötigt werden korrekt,
                umfassend und wahrheitsgemäß anzugeben.</p>
        </li>
        <li>Sperrung des Zugangs / Kündigung
            <ol>
                <li>jameda behält sich vor, bei Verdacht einer missbräuchlichen Nutzung oder wesentlichen
                    Vertragsverletzung diesen Vorgängen nachzugehen, entsprechende Vorkehrungen zu treffen und bei einem
                    begründeten Verdacht den Zugang des Patienten zu sperren. Sollte der Verdacht ausgeräumt werden
                    können, wird die Sperrung wieder aufgehoben, andernfalls steht jameda ein außerordentliches
                    Kündigungsrecht zu.
                </li>
                <li>Jeder Partei steht das Recht zur Kündigung aus wichtigem Grund zu. Die Kündigung bedarf der Textform
                    (z.B. per E-Mail). Mit Wirksamwerden der Kündigung wird der Zugang des Patienten zu den Services von
                    jameda gesperrt.
                </li>
            </ol>
        </li>
        <li>Änderungen/Ergänzung der Nutzungsbedingungen
            <p>jameda behält sich das Recht vor, diese Allgemeinen Geschäftsbedingungen bei Vorliegen einer Lücke in
                dieser Vereinbarung oder bei Eintritt einer Störung des ursprünglichen Verhältnisses von Leistung und
                Gegenleistung jederzeit teilweise oder ganz zu ändern, soweit Sie dadurch nicht unangemessen
                benachteiligt werden. Dies ist insbesondere der Fall, wenn die Änderungen ohne wirtschaftliche Nachteile
                für Sie sind, wie z.B. bei einer Anpassung der Allgemeinen Geschäftsbedingungen an veränderte
                Anmeldeprozeduren sowie an geänderte Funktionalitäten oder Dienste. Über die Änderungen werden Sie
                rechtzeitig per Email oder beim Einloggen informiert, bevor die neuen Allgemeinen Geschäftsbedingungen
                in Kraft treten; dabei halten wir eine angemessene Frist ein. Wenn die Änderungen für Sie nicht
                akzeptabel sind, können Sie Ihre Registrierung jederzeit beenden. Die Änderungen gelten als genehmigt,
                wenn Sie <a href="www.jameda.de" target="_blank">www.jameda.de</a> nach dem Zeitpunkt des Inkrafttretens
                der Änderungen weiter nutzen. Auf diese Rechtsfolgen werden Sie gesondert hingewiesen.</p>
        </li>
        <li>Schlussbestimmungen
            <ol>
                <li>Ausschließlicher Gerichtsstand für alle Streitigkeiten aus diesem Vertrag ist München, sofern das
                    Mitglied Kaufmann ist oder keinen allgemeinen Gerichtsstand im Inland hat; dies gilt auch für den
                    Fall, dass das Mitglied nach Vertragsschluss seinen Wohnsitz oder gewöhnlichen Aufenthalt aus
                    Deutschland heraus verlegt oder sein Wohnsitz oder gewöhnlicher Aufenthalt im Zeitpunkt der
                    Klageerhebung nicht bekannt ist.
                </li>
                <li>Es gilt ausschließlich das Recht der Bundesrepublik Deutschland unter Ausschluss des UN-Kaufrechts
                    und des deutschen Internationalen Privatrechts.
                </li>
                <li>Sollten Bestimmungen dieser allgemeinen Geschäftsbedingungen ganz oder teilweise unwirksam sein oder
                    werden, so bleiben die übrigen Bestimmungen davon unberührt.
                </li>
            </ol>
        </li>
    </ol>
</div>

