import {ChangeDetectionStrategy, Component} from '@angular/core';

@Component({
    selector: 'app-data-security-patientus',
    templateUrl: './data-security-patientus.component.html',
    styleUrls: ['./data-security-patientus.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DataSecurityPatientusComponent {
}
