<div class="cancellation-policy">
    <h2>Widerrufsrecht:</h2>
    <p>
        Sie haben das Recht, binnen vierzehn Tagen ohne Angabe von Gründen diesen Vertrag zu widerrufen. Die
        Widerrufsfrist beträgt vierzehn Tage ab dem Tag des Vertragsabschlusses. Um Ihr Widerrufsrecht auszuüben, müssen
        Sie uns
    </p>
    <p>
        jameda GmbH, Balanstraße 71a, 81541 München<br>
        Tel.: 089 2019 44 94<br>
        Fax: 0800 111 237 686<br>
        E-Mail: <a href="mailto:gesundheit@jameda.de">gesundheit&#64;jameda.de</a>
    </p>
    <p>
        mittels einer eindeutigen Erklärung (z.B. postalisch, per Telefax oder E-Mail) über Ihren Entschluss, diesen
        Vertrag zu widerrufen, informieren. Zur Wahrung der Widerrufsfrist reicht es aus, dass Sie die Mitteilung über
        die Ausübung des Widerrufsrechts vor Ablauf der Widerrufsfrist absenden. Bitte beachten Sie, dass die gesonderte
        Leistungsbeziehung zu dem Heilberufler gesondert widerrufen werden muss, sofern ein Widerrufsrecht hier
        anwendbar ist. Das hier aufgeführte Widerrufsrecht gilt alleine für die Leistungen von jameda.
    </p>
    <h2>Folgen des Widerrufs:</h2>
    <p>
        Wenn Sie diesen Vertrag widerrufen, haben wir Ihnen alle Zahlungen, die wir von Ihnen erhalten haben,
        unverzüglich und spätestens binnen vierzehn Tagen ab dem Tag zurückzuzahlen, an dem die Mitteilung über Ihren
        Widerruf dieses Vertrags bei uns eingegangen ist. Für diese Rückzahlung verwenden wir dasselbe Zahlungsmittel,
        das Sie bei der ursprünglichen Transaktion eingesetzt haben, es sei denn, mit Ihnen wurde ausdrücklich etwas
        anderes vereinbart; in keinem Fall werden Ihnen wegen dieser Rückzahlung Entgelte berechnet.
    </p>
    <p>
        Haben Sie verlangt, dass die Dienstleistungen während der Widerrufsfrist beginnen sollen, so haben Sie uns
        seinen angemessenen Betrag zu zahlen, der dem Anteil der bis zu dem Zeitpunkt, zu dem Sie uns von der Ausübung
        des Widerrufsrechts hinsichtlich dieses Vertrages unterrichten, bereits erbrachten Dienstleistungen im Vergleich
        zum Gesamtumfang der im Vertrag vorgesehenen Dienstleistungen entspricht.
    </p>
</div>
