import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { CancellationPolicyComponent } from './cancellation-policy.component';
import { CancellationPolicyPatientusComponent } from './cancellation-policy-patientus/cancellation-policy-patientus.component';
import { CancellationPolicyAgaplesionComponent } from './cancellation-policy-agaplesion/cancellation-policy-agaplesion.component';

@NgModule({
    imports: [
        CommonModule,
        TranslateModule.forChild()
    ],
    declarations: [
        CancellationPolicyComponent,
        CancellationPolicyPatientusComponent,
        CancellationPolicyAgaplesionComponent
    ],
    exports: [
        CancellationPolicyComponent
    ]
})
export class CancellationPolicyModule {
}
