<!-- Deutsch Version -->
<div class="legal" *ngIf="(currentLanguage$ | async) === 'de' else englishVersion">
    <div>
        <p>
            Helios Kliniken GmbH<br/>
            Friedrichstraße 136, 10117 Berlin<br/>
            <br/>
            030 52 13 21 – 0<br/>
            030 52 13 21 - 1 99<br/>
            <a href="mailto:info@helios-gesundheit.de">
                info&#64;helios-gesundheit.de<br/>
            </a>
        </p>
        <p>
            Geschäftsführer: Dr. Francesco De Meo (V.i.S.d.P.)<br/>
        </p>
        <p>
            Amtsgericht: HR CharlottenBrug B<br/>
            HRB: 159796 B<br/>
            Ust-ID: DE812524991
        </p>
    </div>
    <p>
        <a target="_blank" href="https://www.helios-gesundheit.de/impressum/">
            https://www.helios-gesundheit.de/impressum/
        </a>
    </p>
    <p>
        <strong>Hinweis zur außergerichtlichenStreitbeilegung</strong><br/>
        Die Helios Kliniken GmbH weist gemäß § 36 VSBG darauf hin, dass sie weder bereit noch verpflichtet ist, an einem Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.
    </p>
</div>

<!-- English Version -->
<ng-template #englishVersion>
    <div class="legal">
        <div>
            <p>
                Helios Kliniken GmbH<br/>
                Friedrichstraße 136, 10117 Berlin<br/>
                <br/>
                030 52 13 21 – 0<br/>
                030 52 13 21 - 1 99<br/>
                <a href="mailto:info@helios-gesundheit.de">
                    info&#64;helios-gesundheit.de<br/>
                </a>
            </p>
            <p>
                Management: Dr. Francesco De Meo (V.i.S.d.P.)<br/>
            </p>
            <p>
                District Court: HR Charlottenburg B<br/>
                HRB: 159796 B<br/>
                Ust-ID: DE812524991
            </p>
        </div>
        <p>
            <a target="_blank" href="https://www.helios-gesundheit.de/impressum/">
                https://www.helios-gesundheit.de/impressum/
            </a>
        </p>
        <p>
            <strong>Note on extrajudicial dispute resolution</strong><br/>
            According to § 36 VSBG, Helios Kliniken GmbH as service provider is not obligated to participate in a dispute settlement procedure at a consumer arbitration board.
        </p>
    </div>
</ng-template>
