<div class="page-wrapper">
    <div class="option-card">
        <div class="option-card-header">
            <span class="fas fa-file-alt"></span>
            {{ 'LEGAL.TITLE.CANCELLATION-POLICY' | translate }}
        </div>
        <div class="option-card-body" [ngSwitch]="((brandingData$ | async)?.legalCancellationPolicy)">
            <ng-container *ngSwitchCase="'agaplesion-CP'">
                <app-cancellation-policy-agaplesion></app-cancellation-policy-agaplesion>
            </ng-container>
            <ng-container *ngSwitchDefault>
                <app-cancellation-policy-patientus></app-cancellation-policy-patientus>
            </ng-container>
        </div>
    </div>
</div>
