import {ChangeDetectionStrategy, Component} from '@angular/core';

@Component({
    selector: 'app-cancellation-policy-patientus',
    templateUrl: './cancellation-policy-patientus.component.html',
    styleUrls: ['./cancellation-policy-patientus.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class CancellationPolicyPatientusComponent {
}
