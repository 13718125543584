import {ChangeDetectionStrategy, Component} from '@angular/core';

@Component({
    selector: 'app-imprint-patientus',
    templateUrl: './imprint-patientus.component.html',
    styleUrls: ['./imprint-patientus.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImprintPatientusComponent {
}
