<div class="page-wrapper" *ngIf="isAllowToShowTerm"
     [ngStyle]="{backgroundImage: 'url(' + (backgroundImg$ | async).backgroundImage + ')'}">
    <div class="option-card">
        <div [innerHTML]="'LEGAL.DATA-PROCESS-TERMS.BODY' | translate: {dataSecurityLink: dataSecurityLink}"></div>
        <div class="option-card-footer">
            <div class="link-wrapper">
                <button id="logout" type="button" class="btn btn-outline-dark" (click)="logout()">
                    {{'LEGAL.BUTTON.NO' | translate}}
                </button>
                <button id="accept_button" class="button dark-blue btn-size" (click)="acceptDataProcess()">
                    {{ 'LEGAL.BUTTON.I-ACCEPT' | translate }}
                    <i class="fal fa-check-circle"></i>
                </button>
            </div>
        </div>
        <div id="progress-bar">
            <div class="fill"></div>
        </div>
    </div>
</div>
<div class="page-wrapper" *ngIf="!isAllowToShowTerm"
     [ngStyle]="{backgroundImage: 'url(' + (backgroundImg$ | async).backgroundImage + ')'}">
    <div class="option-card" [innerHTML]="'LEGAL.DATA-PROCESS-TERMS.BODY' | translate: {dataSecurityLink: dataSecurityLink}"></div>
</div>
