import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { DataProcessTermComponent } from './data-process-term.component';
import { DataProcessTermPatientusComponent } from './data-process-term-patientus/data-process-term-patientus.component';
import { BrandingService } from '../../branding/providers/branding.service';
import { RouterModule } from '@angular/router';
import { DataProcessTermHeliosComponent } from './data-process-term-helios/data-process-term-helios.component';

@NgModule({
    imports: [
        RouterModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        NgbModule,
        TranslateModule.forChild()
    ],
    declarations: [
        DataProcessTermComponent,
        DataProcessTermPatientusComponent,
        DataProcessTermHeliosComponent
    ],
    providers: [
        BrandingService
    ],
    exports: [
        DataProcessTermComponent
    ],
})
export class DataProcessTermModule {
}
