import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { BrandingState } from '../../branding/store/branding.state';
import { BrandingService } from '../../branding/providers/branding.service';
import { filter } from 'rxjs/operators';

@Component({
    selector: 'app-cancellation-policy',
    templateUrl: './cancellation-policy.component.html',
    styleUrls: ['./cancellation-policy.component.scss']
})

export class CancellationPolicyComponent implements OnInit {
    public brandingData$: Observable<BrandingState>;

    constructor(private brandingService: BrandingService) {
    }

    ngOnInit(): void {
        this.brandingData$ =
            this.brandingService.getBrandingSettingsFromStore().pipe(
                filter(e => e !== null && e !== undefined)
            );
    }
}
