<div class="option-card-body">
    <div class="option-card-heading content-header">
        <span class="fas fa-balance-scale"></span>
        {{ 'TERMS.DATA-PROCESS-HELIOS.TAB-HEADER' | translate }}
    </div>
    <ul class="term-points">
        <li> {{ 'TERMS.DATA-PROCESS-HELIOS.DESCRIPTION.ONE' | translate }} </li>
    </ul>
    <div class="body-text-normal">
        {{ 'TERMS.DATA-PROCESS-HELIOS.DESCRIPTION.TWO' | translate }}
        <a href="mailto:{{ 'TERMS.HELIOS.MAIL' | translate }}">
            {{ 'TERMS.DATA-PROCESS-HELIOS.MAIL' | translate }}
        </a>
        {{ 'TERMS.DATA-PROCESS-HELIOS.DESCRIPTION.THREE' | translate }}
    </div>
    <br/>
    <div class="body-text-normal">
        {{ 'TERMS.DATA-PROCESS-HELIOS.DESCRIPTION.FOUR' | translate }}
    </div>
</div>


