import { Component } from '@angular/core';
import { CustomTranslateService } from '../../../../providers/translate/services/custom-translate.service';
import { Languages } from '../../../../providers/translate/models/languages';
import { Observable, of } from 'rxjs';

@Component({
    selector: 'app-imprint-helios',
    templateUrl: './imprint-helios.component.html',
    styleUrls: ['./imprint-helios.component.scss']
})
export class ImprintHeliosComponent {
    public currentLanguage$: Observable<Languages> = of(Languages.DE);

    constructor(private customTranslateService: CustomTranslateService) {
        this.currentLanguage$ = this.customTranslateService.getLangFromStore();
    }
}
