import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { BrandingService } from '../branding/providers/branding.service';
import { DataSecurityComponent } from './data-security/data-security.component';
import { ImprintComponent } from './imprint/imprint.component';
import { TermsComponent } from './terms/terms.component';
import { DataSecurityPatientusComponent } from './data-security/data-security-patientus/data-security-patientus.component';
import { ImprintPatientusComponent } from './imprint/imprint-patientus/imprint-patientus.component';
import { TermsPatientusComponent } from './terms/terms-patientus/terms-patientus.component';
import { DataProcessTermModule } from './data-process-term/data-process-term.module';
import { DataSecurityHeliosComponent } from './data-security/data-security-helios/data-security-helios.component';
import { ImprintHeliosComponent } from './imprint/imprint-helios/imprint-helios.component';
import { CancellationPolicyModule } from './cancellation-policy/cancellation-policy.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        NgbModule,
        TranslateModule.forChild(),
        DataProcessTermModule,
        CancellationPolicyModule
    ],
    declarations: [
        DataSecurityComponent,
        ImprintComponent,
        TermsComponent,
        DataSecurityPatientusComponent,
        ImprintPatientusComponent,
        TermsPatientusComponent,
        DataSecurityHeliosComponent,
        ImprintHeliosComponent
    ],
    providers: [
        BrandingService
    ],
    exports: [
        DataSecurityComponent,
        ImprintComponent,
        TermsComponent
    ],
})
export class LegalModule {
    constructor() {
    }
}
