<div class="legal">
    <h5>jameda GmbH</h5>
    <p>Balanstraße 71a<br>
        81541 München<br>
        Tel.: 089 2019 44 94<br>
        Fax: 0800 111 237 686<br>
        <a href="mailto:gesundheit@jameda.de">gesundheit&#64;jameda.de</a>
    </p>
    <p>Geschäftsführerin: Constanze Stypula<br>
        Amtsgericht München, HRB: 168659<br>
        Ust-ID: DE 255275379</p>
    <p>Die jameda GmbH ist eine 100-prozentige Tochter der DocPlanner Group.</p>
    <p>Verantwortlich für die journalistisch-redaktionell gestalteten Inhalte:<br>
        Constanze Stypula, Balanstraße 71a, 81541 München</p>
    <p>Rechtliche Beratung:<br>
        SSB Söder Berlinger Rechtsanwälte PartG mbB, München</p>
    <p>&nbsp;</p>
    <p><strong>Bildquellen:</strong><br>
        © magann - Fotolia.com,<br>
        © Frank - Fotolia.com,<br>
        © Marco2811 - Fotolia.com,<br>
        © maxim - Fotolia.com,<br>
        © reeel - Fotolia.com,<br>
        © Jörg Hackemann - Fotolia.com,<br>
        © ArTo fotolia - Fotolia.com,<br>
        © SergiyN - Fotolia.com,<br>
        © Gabriele Rohde - Fotolia.com,<br>
        © ollimitmrolli - Fotolia.com,<br>
        © crimson - Fotolia.com,<br>
        © GordonGrand - Fotolia.com,<br>
        © Sabine Klein - Fotolia.com,<br>
        © Rainer Schmittchen - Fotolia.com,<br>
        © davis - Fotolia.com,<br>
        © anweber - Fotolia.com,<br>
        © Alex Tihonov - Fotolia.com,<br>
        © A.Z. - Fotolia.com,<br>
        © Mapics - Fotolia.com,<br>
        © eyetronic - Fotolia.com,<br>
        © autofocus67 - Fotolia.com,<br>
        © cpauschert - Fotolia.com,<br>
        © Kaspars Grinvalds - Fotolia.com,<br>
        © fotoherkules - Fotolia.com,<br>
        © Frankix - Fotolia.com,<br>
        © Mikhail Markovski - Fotolia.com,<br>
        © davis - Fotolia.com,<br>
        © VRD - Fotolia.com,<br>
        © Mary Lane - Fotolia.com,<br>
        © chaya1 - Fotolia.com,<br>
        © Manuel Schönfeld - Fotolia.com,<br>
        © kameraauge - Fotolia.com,<br>
        © U. Gernhoefer - Fotolia.com,<br>
        © marcus_hofmann - Fotolia.com,<br>
        © davis fotolia - Fotolia.com,<br>
        © Joerg Sabel - Fotolia.com,<br>
        © Leonid Andronov - Fotolia.com,<br>
        © marlin69 - Fotolia.com,<br>
        © Wolfgang Zwanzger - Fotolia.com,<br>
        © Sliver - Fotolia.com,<br>
        © Mikhail Markovskiy - Fotolia.com,<br>
        © SeanPavonePhoto - Fotolia.com,<br>
        © Fabio Lotti - Fotolia.com,<br>
        © Petair fotolia - Fotolia.com,<br>
        © nilsp - Fotolia.com,<br>
        © Jens Hilberger - Fotolia.com,<br>
        © Blickfang - Fotolia.com,<br>
        © fotobeam.de - Fotolia.com,<br>
        © fotoping - Fotolia.com,<br>
        © franziskus46 - Fotolia.com,<br>
        © goodluz - fotolia.com,<br>
        © StockLite - Shutterstock.com,<br>
        © Monkey Business Images - Shutterstock.com,<br>
        © Jack Frog - Shutterstock.com,<br>
        © Stokkete - Shutterstock.com,<br>
        © mimagephotography - Shutterstock.com,<br>
        © michaeljung - Shutterstock.com,<br>
        © Wavebreak Premium - Shutterstock.com</p>
    <p>Businessman&nbsp;<a href="http://www.freepik.com/free-photos-vectors/business">Business image created by Javi_indy – Freepik.com</a><br>
        Old woman&nbsp;<a href="http://www.freepik.com/free-photos-vectors/people">People image created by Pressfoto – Freepik.com</a><br>
        Icons made by&nbsp;<a title="OCHA" href="https://www.flaticon.com/authors/ocha">OCHA</a>&nbsp;from&nbsp;<a title="Flaticon" href="https://www.flaticon.com/">www.flaticon.com</a>&nbsp;is licensed by&nbsp;<a title="Creative Commons BY 3.0" href="http://creativecommons.org/licenses/by/3.0/" target="_blank" rel="noopener noreferrer">CC 3.0 BY<br>
        </a>Doctor with a tablet&nbsp;<a href="http://www.freepik.com/free-photos-vectors/hand">Hand image created by Freepik<br>
        </a>Icons made by&nbsp;<a title="Zlatko Najdenovski" href="https://www.flaticon.com/authors/zlatko-najdenovski">Zlatko Najdenovski</a>&nbsp;from&nbsp;<a title="Flaticon" href="https://www.flaticon.com/">www.flaticon.com</a>&nbsp;is licensed by&nbsp;<a title="Creative Commons BY 3.0" href="http://creativecommons.org/licenses/by/3.0/" target="_blank" rel="noopener noreferrer">CC 3.0 BY<br>
        </a>Doctor OK&nbsp;<a href="http://www.freepik.com/free-photos-vectors/medical">Medical image created by Freepik<br>
        </a>Doctor woman&nbsp;<a href="http://www.freepik.com/free-photos-vectors/people">People image created by Pressfoto – Freepik.com<br>
        </a>Icons made by&nbsp;<a title="Eucalyp" href="https://www.flaticon.com/authors/eucalyp">Eucalyp</a>&nbsp;from&nbsp;<a title="Flaticon" href="https://www.flaticon.com/">www.flaticon.com</a>&nbsp;is licensed by&nbsp;<a title="Creative Commons BY 3.0" href="http://creativecommons.org/licenses/by/3.0/" target="_blank" rel="noopener noreferrer">CC 3.0 BY<br>
        </a>Credit card, Globe with a lock, file format icons&nbsp;<a href="http://www.freepik.com/">Designed by Freepik</a><br>
        Calendar with a pencil and a clock&nbsp;<a href="http://www.freepik.com/">Designed by Blossomstar / Freepik</a><br>
        Notebook with a pen&nbsp;<a href="http://www.freepik.com/">Designed by Katemangostar / Freepik<br>
        </a>Megaphone icon, Download a picture icon<br>
        Icons made by&nbsp;<a title="Madebyoliver" href="https://www.flaticon.com/authors/madebyoliver">Madebyoliver</a>&nbsp;from&nbsp;<a title="Flaticon" href="https://www.flaticon.com/">www.flaticon.com</a>&nbsp;is licensed by&nbsp;<a title="Creative Commons BY 3.0" href="http://creativecommons.org/licenses/by/3.0/" target="_blank" rel="noopener noreferrer">CC 3.0 BY<br>
        </a>Icons made by&nbsp;<a title="Freepik" href="http://www.freepik.com/">Freepik</a>&nbsp;from&nbsp;<a title="Flaticon" href="https://www.flaticon.com/">www.flaticon.com</a>&nbsp;is licensed by&nbsp;<a title="Creative Commons BY 3.0" href="http://creativecommons.org/licenses/by/3.0/" target="_blank" rel="noopener noreferrer">CC 3.0 BY</a></p>
    <p><strong>Weitere Hinweise<br>
    </strong>Diese Website wurde mit größtmöglicher Sorgfalt zusammengestellt. Trotzdem kann die jameda GmbH für die Fehlerfreiheit und Genauigkeit der enthaltenen Informationen nicht garantieren. Die jameda GmbH schließt jegliche Haftung für Schäden, die direkt oder indirekt aus der Benutzung dieser Website entstehen aus, soweit diese nicht auf Vorsatz oder grober Fahrlässigkeit der jameda GmbH beruhen. Für die Inhalte verlinkter Websites ist allein der jeweilige Anbieter verantwortlich.</p>
    <p>© 2013 – 2022 www.patientus.de, Deutschland.</p>
    <p>Alle Rechte vorbehalten.</p>
    <p>Text, Bilder, Grafiken, Sound, Animationen und Videos der Website unterliegen dem Schutz des Urheberrechts und anderer Schutzgesetze. Der Inhalt dieser Websites darf nicht zu kommerziellen Zwecken kopiert, verbreitet, verändert werden.</p>
</div>

