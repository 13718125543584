<div class="cancellation-policy">
    <h2>Widerrufsrecht:</h2>
    <p>
        Sie haben das Recht, binnen vierzehn Tagen ohne Angabe von Gründen diesen Vertrag zu widerrufen. Die
        Widerrufsfrist beträgt vierzehn Tage ab dem Tag des Vertragsabschlusses. Um Ihr Widerrufsrecht auszuüben, müssen
        Sie die aufgrund der Behandlung für Sie zuständige Einrichtung, mittels einer eindeutigen Erklärung über Ihren
        Entschluss, diesen Vertrag zu widerrufen, informieren. Das geht postalisch oder ganz einfach über die
        nachfolgende Faxnummer oder E-Mail-Adresse:
    </p>
    <p>
        Fax: (069) 95 33 - 891 94 22<br>
        E-Mail: <a href="mailto:telemedizin@agaplesion.de">telemedizin&#64;agaplesion.de</a>
    </p>
    <p>
        Zur Wahrung der Widerrufsfrist reicht es aus, dass Sie die Mitteilung über die Ausübung des Widerrufsrechts vor
        Ablauf der Widerrufsfrist absenden. Bitte beachten Sie, dass die gesonderte Leistungsbeziehung zu dem
        Heilberufler gesondert widerrufen werden muss, sofern ein Widerrufsrecht hier anwendbar ist. Das hier
        aufgeführte Widerrufsrecht gilt alleine für die Leistungen der Videosprechstunde.
    </p>
    <h2>Folgen des Widerrufs:</h2>
    <p>
        Wenn Sie diesen Vertrag widerrufen, haben wir Ihnen alle Zahlungen, die wir von Ihnen ggf erhalten haben,
        unverzüglich, spätestens binnen vierzehn Tagen ab dem Tag zurückzuzahlen, an dem die Mitteilung über Ihren
        Widerruf dieses Vertrags bei uns eingegangen ist. Für diese Rückzahlung verwenden wir dasselbe Zahlungsmittel,
        das Sie bei der ursprünglichen Transaktion eingesetzt haben, es sei denn, mit Ihnen wurde ausdrücklich etwas
        anderes vereinbart; in keinem Fall werden Ihnen wegen dieser Rückzahlung Entgelte berechnet.
    </p>
    <p>
        Haben Sie verlangt, dass die Dienstleistungen während der Widerrufsfrist beginnen sollen, so haben Sie uns einen
        angemessenen Betrag zu zahlen, der dem Anteil der bis zu dem Zeitpunkt, zu dem Sie uns von der Ausübung des
        Widerrufsrechts hinsichtlich dieses Vertrages unterrichten, bereits erbrachten Dienstleistungen im Vergleich zum
        Gesamtumfang der im Vertrag vorgesehenen Dienstleistungen entspricht.
    </p>
</div>
